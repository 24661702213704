<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" name="form" :model="formState" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" name="cinemaId" label="开卡影院">
            <!-- @change="getCardPolicyList" -->
            <a-select
              placeholder="请选择"
              v-model:value="formState.cinemaId"
              style="width: 250px"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option
                v-for="item in cinemaAllList"
                :vlaue="item.id"
                :key="item.id"
              >
                {{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="订单号" name="orderNo" class="ui-form__item">
            <a-input
              v-model:value="formState.orderNo"
              placeholder="请输入订单号"
            ></a-input>
          </a-form-item>
          <a-form-item label="卡号" name="cardNumber" class="ui-form__item">
            <a-input
              v-model:value="formState.cardNumber"
              placeholder="请输入卡号"
            ></a-input>
          </a-form-item>

        </a-row>

        <a-row v-show="showAll">
          <a-form-item label="变动用户手机号" name="phone" class="ui-form__item">
            <a-input
              v-model:value="formState.phone"
              placeholder="请输入变动用户手机号"
            ></a-input>
          </a-form-item>
          <a-form-item label="类型" name="type" class="ui-form__item">
            <a-select
              v-model:value="formState.type"
              style="width: 190px"
              placeholder="请选择类型"
            >
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="1">影片购买</a-select-option>
              <a-select-option :value="2">购买卖品</a-select-option>
              <a-select-option :value="3">影片退款</a-select-option>
              <a-select-option :value="4">卖品退款</a-select-option>
              <a-select-option :value="5">开卡</a-select-option>
              <a-select-option :value="6">小程序充值</a-select-option>
              <a-select-option :value="7">后台充值</a-select-option>
              <a-select-option :value="8">充值退款</a-select-option>

            </a-select>
          </a-form-item>
          <a-form-item class="ui-form__item" label="消费影院" name="consumeCinemaId">
            <a-select placeholder="请选择" v-model:value="formState.consumeCinemaId" style="width: 180px;">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="异店消费" name="isDifferent" class="ui-form__item">
            <a-select v-model:value="formState.isDifferent" placeholder="请选择消费状态" style="width: 190px;">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="发生时间" class="ui-form__item">
            <a-range-picker v-model:value="startTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <span v-permission="['coupon_stored_balanceChangesReport_export']" style="margin-left: 20px;">
							<ExportReport type="cardRecord" :searchData="searchData"></ExportReport>
						</span>
            <a style="margin-left: 20px" @click="showAll = !showAll">
              {{ showAll ? "收起" : "展开" }}
              <Icon v-show="!showAll" icon="DownOutlined"></Icon>
              <Icon v-show="showAll" icon="UpOutlined"></Icon>
            </a>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button type="primary" html-type="submit">搜索</a-button>
            <a-button style="margin-left: 20px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        style="margin-top: 10px"
        :dataSource="list"
        :columns="columns"
        :pagination="pagination"
        :scroll="{ x: 1600 }"
      >
        <template #bodyCell="{ column, record, index }">
          <template v-if="column.key === 'createTime'">
            <div>
              {{ transDateTime(record.createTime) }}
            </div>
          </template>
          <template v-if="column.key === 'printTime'">
            <div>
              {{ transDateTime(record.printTime) }}
            </div>
          </template>
          <template v-if="column.key === 'orderStatus'">
            <div>
              {{ transStatus(record.orderStatus,record.type) }}
            </div>
          </template>
          <template v-if="column.key === 'refundTime'">
            <div>
              {{ transDateTime(record.refundTime) }}
            </div>
          </template>
          <template v-if="column.key === 'signTime'">
            <div>
              {{ transDateTime(record.signTime) }}
            </div>
          </template>
          <template v-if="column.key === 'openCardType'">
            <div>
              {{ record.openCardType == 1 ? '管理后台':'订单' }}
            </div>
          </template>


          <template v-if="column.key === 'goodsInfo'">
            <div class="ui-goods" v-for="item in record.orderItemList" :key="item.id">
              <div style="display: flex;align-items: flex-start;">
                <div style="margin-left: 8px;">
                  <div style="font-size: 12px;">
                    <a-tag v-if="item.goodsType === 1" color="blue">普通卖品</a-tag>
                    <a-tag v-if="item.goodsType === 5" color="green">套餐卖品</a-tag>
                    <a-tag v-if="item.cardBalance" style="padding: 2px;line-height: 12px;"
                           color="orange" size="small">次卡</a-tag>
                    {{ item.goodsTitle }}
                  </div>
                </div>
              </div>
<!--              <div v-if="item.cardBalance" style="margin-top: 4px;font-size: 12px;">-->
<!--                <div>卡等级: {{ item.cardTitle }}</div>-->
<!--                <div v-if="item.cardNumberList">卡号:-->
<!--                  <span v-for="(subItem, subIndex) in item.cardNumberList" :key="subItem">-->
<!--											{{ subItem }}-->
<!--											<span v-if="subIndex < item.cardNumberList.length - 1">、</span>-->
<!--										</span>-->
<!--                </div>-->
<!--                <div>观影次数: {{ item.cardBalance }}</div>-->
<!--              </div>-->
<!--              <div v-if="item.goodsInfoVO.isCoupon" style="margin-top: 4px;font-size: 12px;">-->
<!--                <div style="margin-bottom: 5px;" v-if="item.goodsInfoVO.ticketBatchNum">-->
<!--                  <div>兑换券批次号: {{ item.goodsInfoVO.ticketCouponList.length ? item.goodsInfoVO.ticketCouponList[0].batchNo : '' }}</div>-->
<!--                  <div>兑换券数量: {{ item.goodsInfoVO.ticketBatchNum }}张</div>-->
<!--                  <div>-->
<!--                    兑换券号：-->
<!--                    <a-tag v-for="(item, i) in item.goodsInfoVO.ticketCouponList" :key="i">-->
<!--                      {{ item.barcode }}-->
<!--                    </a-tag>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-if="item.goodsInfoVO.snackBatchNum">-->
<!--                  <a-divider style="margin: 6px 0;" v-if="item.goodsInfoVO.ticketBatchNum"/>-->
<!--                  <div>卖品券批次号: {{ item.goodsInfoVO.snackCouponList.length ? item.goodsInfoVO.snackCouponList[0].batchNo : '' }}</div>-->
<!--                  <div>卖品券数量: {{ item.goodsInfoVO.snackBatchNum }}张</div>-->
<!--                  <div>卖品券号:-->
<!--                    <a-tag v-for="(item, i) in item.goodsInfoVO.snackCouponList" :key="i">-->
<!--                      {{ item.barcode }}-->
<!--                    </a-tag>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </template>



          <template v-if="column.key === 'type'">
            <span v-if="record.type == 1">影片购买</span>
            <span v-if="record.type == 2">购买卖品</span>
            <span v-if="record.type == 3">影片退款</span>
            <span v-if="record.type == 4">卖品退款</span>
            <span v-if="record.type == 5">开卡</span>
            <span v-if="record.type == 6">小程序充值</span>
            <span v-if="record.type == 7">后台充值</span>
            <span v-if="record.type == 8">充值退款</span>
          </template>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import {
  getOrganizationList,
  getCinemaList,
} from "@/service/modules/cinema.js";
import {
  cardChangesReport
} from "@/service/modules/coupon.js";
import ExportReport from "@/components/exportReport/exportReport.vue";
export default {
  components: { Icon, ExportReport},
  data() {
    return {
      loading: false,
      showAll: false,
      organizationList: [],
      cinemaAllList: [],
      cardAllList: [],
      formState: {
        cardType:3,
        type:'',
        consumeCinemaId: '',
        isDifferent: ''
      },
      startTime: [],
      searchData: {},
      list: [],
      columns: [
        {
          title: "消费影院",
          dataIndex: "consumeCinemaName",
          key:'consumeCinemaName',
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "开卡影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      columns2: [
        {
          title: "消费影院",
          dataIndex: "consumeCinemaName",
          key:'consumeCinemaName',
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "开卡影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      openCardcolumns: [
        {
          title: "消费影院",
          dataIndex: "consumeCinemaName",
          key:'consumeCinemaName',
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "开卡影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "开卡方式",
          key: "openCardType",
          width: 150,
        },
        {
          title: "开卡人",
          dataIndex: "",
          width: 150,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      buySanckcolumns: [
        {
          title: "消费影院",
          dataIndex: "consumeCinemaName",
          key:'consumeCinemaName',
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "开卡影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "卖品信息",
          key: "goodsInfo",
          width: 220,
        },
        {
          title: "核销时间",
          key: "signTime",
          width: 220,
        },
        {
          title: "核销人",
          dataIndex: "verificationUserName",
          width: 220,
        },
        {
          title: "订单状态",
          key: "orderStatus",
          width: 220,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      sanckRefundcolumns: [
        {
          title: "消费影院",
          dataIndex: "consumeCinemaName",
          key:'consumeCinemaName',
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "开卡影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "卖品信息",
          key: "goodsInfo",
          width: 220,
        },
        {
          title: "核销时间",
          key: "signTime",
          width: 220,
        },
        {
          title: "核销人",
          dataIndex: "verificationUserName",
          width: 220,
        },
        {
          title: "订单状态",
          key: "orderStatus",
          width: 220,
        },        {
          title: "售后时间",
          key: "refundTime",
          width: 220,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      buyCinemacolumns: [
        {
          title: "消费影院",
          dataIndex: "consumeCinemaName",
          key:'consumeCinemaName',
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "开卡影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "影片信息",
          dataIndex: "filmName",
          width: 220,
        },
        {
          title: "取票时间",
          key: "printTime",
          width: 220,
        },
        {
          title: "订单状态",
          key: "orderStatus",
          width: 220,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      cinemaRefundcolumns: [
        {
          title: "消费影院",
          dataIndex: "consumeCinemaName",
          key:'consumeCinemaName',
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "开卡影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "影片信息",
          dataIndex: "filmName",
          width: 220,
        },
        {
          title: "订单状态",
          key: "orderStatus",
          width: 220,
        },
        {
          title: "售后时间",
          key: "refundTime",
          width: 220,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      appColumns: [
        {
          title: "消费影院",
          dataIndex: "consumeCinemaName",
          key:'consumeCinemaName',
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "开卡影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "充值活动名称",
          dataIndex: "activityName",
          width: 220,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      backgroundColumns: [
        {
          title: "消费影院",
          dataIndex: "consumeCinemaName",
          key:'consumeCinemaName',
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "开卡影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "操作人",
          dataIndex: "verificationUserName",
          width: 220,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      rechargeColumns: [
        {
          title: "消费影院",
          dataIndex: "consumeCinemaName",
          key:'consumeCinemaName',
          width: 220,
        },
        {
          title: "订单号",
          dataIndex: "orderNo",
          width: 220,
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "变动用户手机号",
          dataIndex: "phone",
          width: 160,
        },
        {
          title: "类型",
          key: "type",
          width: 120,
        },
        {
          title: "开卡影院",
          dataIndex: "cinemaName",
          width: 220,
        },
        {
          title: "操作人",
          dataIndex: "verificationUserName",
          width: 220,
        },
        {
          title: "发生前余额",
          dataIndex: "beforeBalance",
          width: 150,
        },
        {
          title: "变动金额",
          dataIndex: "changeBalance",
          width: 150,
        },
        {
          title: "发生后余额",
          dataIndex: "afterBalance",
          width: 150,
        },
        {
          title: "发生时间",
          key: "createTime",
          width: 220,
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ["10", "20", "50", "100", "500"],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        },
      },
    };
  },
  created() {
    this.getOrganizationList();
  },
  methods: {
    onBack(isRef) {
      if (isRef) {
        this.getData();
      }
    },
    selectColumns(type){
      console.log(type,'type')
      if (type == 1){
        //购买影票
        return this.buyCinemacolumns;
      }else if (type == 2){
        //购买卖品
        return this.buySanckcolumns
      }else if (type == 3){
        //影票售后
        return this.cinemaRefundcolumns
      }else if (type == 4){
        //卖品售后
        return this.sanckRefundcolumns
      }else if (type == 5){
        //开卡
        return this.openCardcolumns
      }else if (type == 6){
        //小程序充值
        return this.appColumns
      }else if (type == 7){
        //后台充值
        return this.backgroundColumns
      }else if (type == 8){
        //充值退款
        return this.rechargeColumns
      }else if (type == ''){
        return this.columns2
      }

    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
      this.searchData.type = this.searchData.type != ''
        ? this.searchData.type
        : undefined;
      if (this.startTime && this.startTime.length) {
        this.searchData.startCreateTime = this.startTime[0]
          .startOf("days")
          .unix();
        this.searchData.endCreateTime = this.startTime[1].endOf("days").unix();
      }
      this.searchData.isDifferent = this.searchData.isDifferent !== '' ? this.searchData.isDifferent : undefined
      this.searchData.consumeCinemaId = this.searchData.consumeCinemaId !== '' ? this.searchData.consumeCinemaId :undefined
      this.getData();
    },
    reset() {
      this.$refs.form.resetFields();
      this.startTime = [];
      if (this.organizationList.length) {
        this.formState.organizationId = this.organizationList[0].id;
        this.getAllCinemaList(this.formState.organizationId);
      }
      this.onSearch();
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await cardChangesReport({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData,
        });
        this.loading = false;
        if (ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
          this.columns = this.selectColumns(this.formState.type)
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getOrganizationList() {
      this.loading = true;
      try {
        let ret = await getOrganizationList({
          page: 1,
          pageSize: 999999,
        });
        if (ret.code === 200) {
          this.organizationList = ret.data.list;
          if (this.organizationList.length) {
            this.formState.organizationId = this.organizationList[0].id;
            this.getAllCinemaList(this.formState.organizationId);
          }
          this.onSearch();
        } else {
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getAllCinemaList(organizationId) {
      this.cinemaAllList = [];
      this.formState.cinemaId = 0;
      this.cardAllList = [];
      // this.formState.cardId = 0;
      let ret = await getCinemaList({
        page: 1,
        pageSize: 999999,
        organizationId: organizationId ? organizationId : undefined,
      });
      if (ret.code === 200) {
        this.cinemaAllList = ret.data.list;
      }
    },
    transStatus(val, type) {
      if (val === 'WAIT_BUYER_PAY') {
        return '待支付';
      }
      if (val === 'SYSTEM_CANCEL') {
        return '系统超时取消';
      }
      if (val === 'CUSTOMER_CANCEL') {
        return '用户自行取消';
      }
      if (val === 'WAIT_SELLER_SEND_GOODS') {
        return '待发货'
      }
      if (val === 'WAIT_CHECK') {
        return '待核销'
      }
      if (val === 'TRADE_FINISH') {
        if (type === 2 || type === 4) {
          return '已核销'
        } else {
          return '订单完成'
        }
      }
      if (val === 'WAIT_BUYER_CONFIRM_GOODS') {
        return '待收货'
      }
      if (val === 'AFTERSALE_FINISH') {
        if (type === 2 || type === 4) {
          return '已退款'
        } else {
          return '已售后'
        }
      }
      if (val === 'CHECKOUT_SUCCESS') {
        return '已下单'
      }
      if (val === 'WAIT_COMMENT') {
        return '已签收'
      }
    },
  },
};
</script>

<style scoped>
.ui-form__item {
  margin-right: 20px;
}
</style>
